import { useQuery } from "@apollo/client"
import { DotsHorizontalIcon } from "@radix-ui/react-icons"
import { useEffect, useMemo, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { gql } from "~/__generated__"
import {
  Automation_AdminFragment,
  AutomationCategoryEnum,
  AutomationRun_AdminFragment,
  JobClassEnum,
  RulesEngineEffectTypeEnum,
} from "~/__generated__/graphql"
import { adminAutoMessageEditPath, adminAutoMessagesPath } from "~/common/paths"
import { cn } from "~/lib/utils"
import { Badge } from "~/ui/badge"
import { Button } from "~/ui/button"
import { Card } from "~/ui/card"
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
} from "~/ui/context-menu"
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
  TableCell,
} from "~/ui/table"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "~/ui/tabs"
import { AvatarWithFallback } from "~/ui/AvatarWithFallback"
import { InfiniteLoadMore } from "~/ui/InfiniteLoadMore"
import { RUN_JOB_MUTATION } from "../AdminDebugSettingsScreen"
import { useSafeMutation } from "~/common/useSafeMutation"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import toast from "react-hot-toast"
import { UserName } from "~/directory/UserName"
import { useUserDialogContext } from "~/directory/UserDialogContext"
import { formatDate } from "~/common/formatDate"
import { FormattedContent } from "~/components/FormattedContent"
import { IconButton } from "~/ui/IconButton"
import {
  ChevronDownIcon,
  ChevronRightIcon,
  MessageSquareCode,
} from "lucide-react"
import { Label } from "~/ui/label"
import { UserNameAndAvatar } from "~/directory/UserNameAndAvatar"
import { AdminHeader } from "~/admin/AdminHeader"

export const AdminAutoMessagesScreen = () => {
  const navigate = useNavigate()
  const { activeTab: activeTabFromParams } = useParams()
  const [activeTab, setActiveTab] = useState(activeTabFromParams || "")

  useEffect(() => {
    if (activeTab !== activeTabFromParams) {
      navigate(adminAutoMessagesPath({ "activeTab?": activeTab }))
    }
  }, [activeTab, activeTabFromParams, navigate])

  const onTabChange = (tab: string) => {
    setActiveTab(tab)
  }

  return (
    <>
      <AdminHeader
        title={"Auto Messages"}
        subtitle={"Send automated messages to users based on triggers"}
        Icon={MessageSquareCode}
      >
        <Button
          onClick={() =>
            navigate(adminAutoMessageEditPath({ automationId: "new" }))
          }
        >
          New Auto Message
        </Button>
      </AdminHeader>
      <Tabs value={activeTab} onValueChange={onTabChange}>
        <TabsList className="justify-start mb-4 text-foreground">
          <TabsTrigger value="">Configurations</TabsTrigger>
          <TabsTrigger value="previous-runs">Previous Runs</TabsTrigger>
        </TabsList>
        <TabsContent value="">
          <AutomationsTable />
        </TabsContent>
        <TabsContent value="previous-runs">
          <AutomationRunsTable />
        </TabsContent>
      </Tabs>
    </>
  )
}

const AutomationsTable = () => {
  const {
    data: currentData,
    previousData,
    loading,
    fetchMore,
  } = useQuery(AUTO_MESSAGES_QUERY_DOCUMENT, {
    variables: {
      categories: [AutomationCategoryEnum.AutoMessage],
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  })

  const data = currentData || previousData

  const automations: any[] = useMemo(() => {
    return data?.automations?.edges.map((e) => e.node) || []
  }, [data])

  const hasNextPage = !!data?.automations?.pageInfo.hasNextPage

  return (
    <>
      {(automations.length > 0 || loading) && (
        <Card>
          <Table className={cn("rounded-tl-2xl")}>
            <TableHeader>
              <TableRow className="rounded-tl-2xl">
                <TableHead>Name</TableHead>
                <TableHead>Description</TableHead>
                <TableHead>Sender</TableHead>
                <TableHead>Trigger Event</TableHead>
                <TableHead>Active</TableHead>
                <TableHead className="text-right">Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {automations.length > 0 &&
                automations.map((automation) => (
                  <AutomationRow key={automation.id} automation={automation} />
                ))}
            </TableBody>
          </Table>
        </Card>
      )}

      {!loading && automations.length === 0 && (
        <div className="text-center text-gray-500">No automations found.</div>
      )}

      <div className="p-16 w-full flex items-center justify-center">
        <InfiniteLoadMore
          onEndReached={() =>
            fetchMore({
              variables: {
                after: data?.automations?.pageInfo.endCursor,
              },
            })
          }
          canLoadMore={!loading && hasNextPage}
          loadingText="Loading more automations..."
          loading={loading && automations.length > 0 && hasNextPage}
        />
      </div>
    </>
  )
}

const AutomationRow = ({
  automation,
}: {
  automation: Automation_AdminFragment
}) => {
  const { currentUser } = useCurrentUser()
  const navigate = useNavigate()
  const contextMenuTriggerRef = useRef<HTMLTableRowElement>(null)

  const triggerContextMenu = (e: React.MouseEvent) => {
    if (!contextMenuTriggerRef.current) return
    const event = new MouseEvent("contextmenu", {
      bubbles: true,
      cancelable: true,
      view: window,
      clientX: e.clientX,
      clientY: e.clientY,
    })
    contextMenuTriggerRef.current?.dispatchEvent(event)
  }

  const [runJob] = useSafeMutation(RUN_JOB_MUTATION)
  const sendTestMessage = async () => {
    const { data, errors } = await runJob({
      variables: {
        input: {
          jobClass: JobClassEnum.RunAutomationJob,
          kwargs: {
            automation_id: automation.id,
            user_id: currentUser.id,
            force: true,
          },
        },
      },
    })

    if (errors) {
      return toast.error("Failed to run automation")
    }

    toast.success(`Automation ${data?.jobRun.job.jobId} started`)
  }

  const fromUserId = useMemo(() => {
    return automation.effects.find(
      (effect: any) =>
        effect.type === RulesEngineEffectTypeEnum.SendDirectMessage
    )?.params.from_user_id
  }, [automation.effects])

  return (
    <ContextMenu>
      <ContextMenuContent>
        <ContextMenuItem
          className="cursor-pointer"
          onClick={() =>
            navigate(adminAutoMessageEditPath({ automationId: automation.id }))
          }
        >
          Edit Auto Message
        </ContextMenuItem>
        <ContextMenuItem className="cursor-pointer" onClick={sendTestMessage}>
          Send Test Message
        </ContextMenuItem>
      </ContextMenuContent>
      <ContextMenuTrigger asChild>
        <TableRow ref={contextMenuTriggerRef} className="group">
          <TableCell className="border-0 px-4">
            <div className="max-w-36 truncate">{automation.name}</div>
          </TableCell>

          <TableCell className="border-0 px-4">
            <div className="max-w-64 truncate">{automation.description}</div>
          </TableCell>

          <TableCell className="border-0 px-4">
            <UserNameAndAvatar userId={fromUserId} />
          </TableCell>

          <TableCell className="border-0 px-4">
            {automation.triggerEventName}
          </TableCell>

          <TableCell className="border-0 px-4">
            <Badge variant={automation.active ? "default" : "secondaryOutline"}>
              {automation.active ? "Yes" : "No"}
            </Badge>
          </TableCell>

          <TableCell className="border-0 px-4">
            <div className="flex justify-end gap-2">
              <Button variant="ghost" size="icon" onClick={triggerContextMenu}>
                <DotsHorizontalIcon />
              </Button>
            </div>
          </TableCell>
        </TableRow>
      </ContextMenuTrigger>
    </ContextMenu>
  )
}

const AutomationRunsTable = () => {
  const {
    data: currentData,
    previousData,
    loading,
    fetchMore,
  } = useQuery(AUTOMATION_RUNS_QUERY_DOCUMENT, {
    variables: {
      categories: [AutomationCategoryEnum.AutoMessage],
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  })

  const data = currentData || previousData

  const automationRuns: any[] = useMemo(() => {
    return data?.automationRuns?.edges.map((e) => e.node) || []
  }, [data])

  const hasNextPage = !!data?.automationRuns?.pageInfo.hasNextPage

  return (
    <>
      {(automationRuns.length > 0 || loading) && (
        <Card>
          <Table className={cn("rounded-tl-2xl")}>
            <TableHeader>
              <TableRow className="rounded-tl-2xl">
                <TableHead>Auto Message Name</TableHead>
                <TableHead>To</TableHead>
                <TableHead>From</TableHead>
                <TableHead>Sent At</TableHead>
                <TableHead className="text-right">Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {automationRuns.length > 0 &&
                automationRuns.map((automationRun) => (
                  <AutomationRunRow
                    key={automationRun.id}
                    automationRun={automationRun}
                  />
                ))}
            </TableBody>
          </Table>
        </Card>
      )}

      {!loading && automationRuns.length === 0 && (
        <div className="text-center text-gray-500">
          No automation runs found.
        </div>
      )}

      <div className="p-16 w-full flex items-center justify-center">
        <InfiniteLoadMore
          onEndReached={() =>
            fetchMore({
              variables: {
                after: data?.automationRuns?.pageInfo.endCursor,
              },
            })
          }
          canLoadMore={!loading && hasNextPage}
          loadingText="Loading more automation runs..."
          loading={loading && automationRuns.length > 0 && hasNextPage}
        />
      </div>
    </>
  )
}

const AutomationRunRow = ({
  automationRun,
}: {
  automationRun: AutomationRun_AdminFragment
}) => {
  const navigate = useNavigate()
  const contextMenuTriggerRef = useRef<HTMLTableRowElement>(null)

  const triggerContextMenu = (e: React.MouseEvent) => {
    if (!contextMenuTriggerRef.current) return
    const event = new MouseEvent("contextmenu", {
      bubbles: true,
      cancelable: true,
      view: window,
      clientX: e.clientX,
      clientY: e.clientY,
    })
    contextMenuTriggerRef.current?.dispatchEvent(event)
  }

  const sendDirectMessageOutput = useMemo(() => {
    return automationRun.outputs.find(
      (o: any) => o.effect_type === RulesEngineEffectTypeEnum.SendDirectMessage
    )
  }, [automationRun.outputs])

  const { openUserDialog } = useUserDialogContext()

  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <>
      <ContextMenu>
        <ContextMenuContent>
          <ContextMenuItem
            className="cursor-pointer"
            onClick={() =>
              navigate(
                adminAutoMessageEditPath({
                  automationId: automationRun.automation.id,
                })
              )
            }
          >
            View Auto Message
          </ContextMenuItem>
        </ContextMenuContent>
        <ContextMenuTrigger asChild>
          <TableRow ref={contextMenuTriggerRef} className="group">
            <TableCell className="border-0 px-4">
              <div className="flex items-center gap-2">
                <IconButton onClick={() => setIsExpanded(!isExpanded)}>
                  {isExpanded ? (
                    <ChevronDownIcon className="w-4 h-4" />
                  ) : (
                    <ChevronRightIcon className="w-4 h-4" />
                  )}
                </IconButton>
                {automationRun.automation.name}
              </div>
            </TableCell>

            <TableCell className="border-0 px-4">
              {automationRun.user && (
                <div
                  className="flex items-center gap-2 cursor-pointer"
                  onClick={() => openUserDialog(automationRun.user!.id)}
                >
                  <AvatarWithFallback user={automationRun.user} />
                  <div className="text-[13px] font-semibold">
                    <UserName user={automationRun.user} />
                  </div>
                </div>
              )}
            </TableCell>

            <TableCell className="border-0 px-4">
              <div
                className="flex items-center gap-2 cursor-pointer"
                onClick={() => openUserDialog(automationRun.user!.id)}
              >
                <div className="text-[13px] font-semibold">
                  {sendDirectMessageOutput?.from_user_name}
                </div>
              </div>
            </TableCell>

            <TableCell className="border-0 px-4">
              {formatDate(automationRun.createdAt, "MMM d, yyyy @ h:mm a")}
            </TableCell>

            <TableCell className="border-0 px-4">
              <div className="flex justify-end gap-2">
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={triggerContextMenu}
                >
                  <DotsHorizontalIcon />
                </Button>
              </div>
            </TableCell>
          </TableRow>
        </ContextMenuTrigger>
      </ContextMenu>
      <TableRow className={cn("group", !isExpanded && "hidden")}>
        <TableCell className="border-0 px-4" colSpan={6}>
          <div className="flex flex-col gap-4 p-4">
            <Label>Message Content</Label>
            <FormattedContent content={sendDirectMessageOutput?.post_content} />
          </div>
        </TableCell>
      </TableRow>
    </>
  )
}

gql(`
  fragment RulesEngineEffect_Admin on RulesEngineEffect {
    id
    type
    priority
    params
  }
`)

gql(`
  fragment Automation_Admin on Automation {
    id
    category
    name
    description
    active
    triggerEventName
    triggerDelaySeconds
    conditions
    effects {
      ...RulesEngineEffect_Admin
    }
  }
`)

const AUTO_MESSAGES_QUERY_DOCUMENT = gql(`
  query AutoMessages(
    $categories: [AutomationCategoryEnum!],
    $first: Int,
    $after: String,
  ) {
    automations(
      categories: $categories,
      first: $first,
      after: $after
    ) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...Automation_Admin
        }
      }
    }

  }
`)

gql(`
  fragment AutomationRun_Admin on AutomationRun {
    id
    createdAt
    outputs
    user {
      ...User_Avatar
    }
    automation {
      ...Automation_Admin
    }
  }
`)

const AUTOMATION_RUNS_QUERY_DOCUMENT = gql(`
  query AutomationRunsQuery(
    $categories: [AutomationCategoryEnum!],
    $first: Int,
    $after: String,
  ) {
    automationRuns(
      categories: $categories,
      first: $first,
      after: $after
    ) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...AutomationRun_Admin
        }
      }
    }

  }
`)

import SafeSpaceLogo from "~/images/safe-space-logo.svg?react"
import BoardroomLogoDarkMode from "~/images/boardroom-logo.svg?react"
import BoardroomLogo from "~/images/boardroom-logo-light.svg?react"
import MarketinglandLogo from "~/images/marketingland-logo.svg?react"
import SafeSpaceLogoSquare from "~/images/safespace-logo-square.svg?react"
import BoardroomLogoSquare from "~/images/boardroom-logo-square.svg?react"
import MarketinglandLogoSquare from "~/images/marketingland-logo-square.svg?react"
import { CommunitySlug } from "~/__generated__/graphql"
import { useCommunity } from "./useCommunity"
import { useMemo } from "react"

interface CommunityLogoProps {
  className?: string
  variant?: "square" | "default" | "darkMode"
}

export const CommunityLogo = ({
  className,
  variant = "default",
}: CommunityLogoProps) => {
  const community = useCommunity()

  const Logo = useMemo(() => {
    switch (community.slug) {
      case CommunitySlug.Safespace:
        return variant === "square" ? SafeSpaceLogoSquare : SafeSpaceLogo
      case CommunitySlug.Boardroom:
        return variant === "square"
          ? BoardroomLogoSquare
          : variant === "darkMode"
          ? BoardroomLogoDarkMode
          : BoardroomLogo
      case CommunitySlug.Marketingland:
        return variant === "square"
          ? MarketinglandLogoSquare
          : MarketinglandLogo
      default:
        return null
    }
  }, [community.slug, variant])

  if (!Logo) return null
  return <Logo className={className} />
}

import { Button } from "~/ui/button"
import { Translation } from "~/common/Translation"
import { z } from "zod"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { Form } from "~/ui/form"
import { TextField } from "~/components/forms/TextField"
import { CheckboxField } from "~/components/forms/CheckboxField"
import { Link } from "~/ui/Link"
import { useCommunity } from "~/community/useCommunity"
import {
  signInPath,
  signInTokenPath,
  stripeCheckoutSessionFreePath,
  subscriberAgreementPath,
  welcomePath,
} from "~/common/paths"
import { H1, Muted, typographyVariants } from "~/ui/typography"
import { gql } from "~/__generated__"
import { useSafeMutation } from "~/common/useSafeMutation"
import { useNavigate } from "react-router-dom"
import toast from "react-hot-toast"
import { displayErrors } from "~/common/validations"
import { useCurrentUserMaybe } from "~/auth/CurrentUserContext"
import { CommunityLogo } from "~/community/CommunityLogo"
import { FeatureFlag } from "~/common/FeatureFlag"
import { FeatureFlagEnum } from "~/__generated__/graphql"
import { useEffect } from "react"

const formSchema = z.object({
  email: z.string().email({ message: "Invalid email address" }),
  consent: z.boolean().refine((value) => value === true, {
    message: "You must agree to the terms and conditions to subscribe",
  }),
})

export type FormValues = z.infer<typeof formSchema>

export const SignUpScreen = () => {
  const { slug, termsOfUseUrl, privacyPolicyUrl } = useCommunity()
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      consent: false,
    },
  })
  const [runUserSignup] = useSafeMutation(USER_SIGNUP_MUTATION)
  const navigate = useNavigate()
  const { result } = useCurrentUserMaybe()

  useEffect(() => {
    document.body.classList.add("onboarding")
    document.body.classList.add(`onboarding-${slug.toLowerCase()}`)
    return () => {
      document.body.classList.remove("onboarding")
      document.body.classList.remove(`onboarding-${slug.toLowerCase()}`)
    }
  }, [slug])

  const handleSubmit = async ({ email }: FormValues) => {
    const { data, errors } = await runUserSignup({
      variables: { input: { email } },
    })

    if (errors) {
      // Handle error
      displayErrors(errors)
      return
    }

    if (data?.userSignup.sessionId) {
      // The user already existed and we should redirect the sign in token screen.
      toast.success(
        "It looks like you already have an account. Please sign in to continue."
      )
      navigate(signInTokenPath({ sessionId: data.userSignup.sessionId }))
      return
    }

    await result.refetch()
    navigate(welcomePath.pattern)
  }

  if (!FeatureFlag.get(FeatureFlagEnum.SignupInApp)) {
    window.location.href = stripeCheckoutSessionFreePath.pattern
    return null
  }

  return (
    <div className="container mx-auto lg:pt-32 lg:flex lg:justify-between lg:items-start min-h-screen lg:before:content-[' '] lg:before:block lg:before:fixed lg:before:w-1/2 lg:before:left-1/2 lg:before:top-0 lg:before:h-full lg:before:bg-card lg:before:border-l-mercury lg:before:drop-shadow-lg lg:before:z-[-1]">
      <div className="w-full lg:max-w-1/2">
        <div className="p-8 lg:p-16 flex flex-col gap-4">
          <CommunityLogo className="" variant="darkMode" />
          <H1 variant="h6">
            <Translation ns="signup" i18nKey="title" />
          </H1>
          <div className="flex items-center gap-2">
            <div className={typographyVariants({ variant: "h2" })}>$0.00</div>
            <Muted>per year</Muted>
          </div>
        </div>
      </div>
      <div className="w-full lg:max-w-1/2">
        <div className="p-8 lg:p-16 lg:pt-20 flex flex-col gap-8">
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(handleSubmit)}
              className="flex flex-col gap-6"
            >
              <TextField
                control={form.control}
                name="email"
                label="Email"
                required={true}
              />
              <CheckboxField
                control={form.control}
                name="consent"
                label={
                  <Translation
                    ns="signup"
                    i18nKey="consent"
                    components={{
                      TermsOfService: (
                        <Link
                          href={termsOfUseUrl || undefined}
                          target="_blank"
                          rel="noreferrer nofollow"
                        />
                      ),
                      PrivacyPolicy: (
                        <Link
                          href={privacyPolicyUrl || undefined}
                          target="_blank"
                          rel="noreferrer nofollow"
                        />
                      ),
                      SubscriberAgreement: (
                        <Link to={subscriberAgreementPath.pattern} />
                      ),
                    }}
                  />
                }
                labelClassName="font-normal text-2xs text-left"
                required
              />
              <Button
                className="w-full"
                variant="onboarding"
                disabled={
                  form.formState.isSubmitting || !form.formState.isValid
                }
                type="submit"
              >
                <Translation ns="signup" i18nKey="submit" />
              </Button>
            </form>
          </Form>
          <Muted className="text-center w-full">
            <Translation ns="signup" i18nKey="confirmation" />
          </Muted>
          <Muted className="text-center w-full">
            Already a member? <Link to={signInPath.pattern}>Sign In</Link>
          </Muted>
        </div>
      </div>
    </div>
  )
}

const USER_SIGNUP_MUTATION = gql(`
      mutation UserSignup($input: UserSignupInput!) {
        userSignup(input: $input) {
        user {id}
      sessionId
    }
  }
      `)
